.modal-logo{
    // position: absolute;
    // top: 10%;
    z-index: 2;
    // left: auto;
    // right: 45%;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // padding-top: 57px;
    // background-color: red;
}

.privacy-policy-field{
    // position: absolute;
    // bottom: 50px;
    z-index: 2;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    font-size: 16px;
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    // padding-bottom: 50px;
    // background-color: red;
    padding: 0 10px;
}